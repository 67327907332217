import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/proforma/invoices', config);
}

const get = async (id) => {
    return FinanceApi.get('/proforma/invoices/' + id);
}

const download = async (id) => {
    return FinanceApi.get('/proforma/invoices/' + id + '/download', {responseType: 'arraybuffer'});
}

const del = async (id) => {
    return FinanceApi.delete('/proforma/invoices/' + id);
}

const store = async (data) => {
    return FinanceApi.post('/proforma/invoices/', data);
}

const update = async (id, data) => {
    return FinanceApi.put('/proforma/invoices/' + id, data);
}
export default {
    get, getAll, store, update, del, download
}
