<template>
    <div>
        <div class="row" v-if="data">
            <b-table
                v-if="data.length>0"
                bordered
                striped
                responsive
                :items="data"
                :fields="fields"
                show-empty
                class="mb-5 table-dropdown no-scrollbar border rounded">
                <template #cell(created_at)="row">
                    <b-button class="text-info" variant="link" size="xs"
                              @click="download(row.item)">
                        {{ row.item.created_at }}
                    </b-button>
                </template>
                <template #cell(dropdownTable)="row">
                    <div class="d-flex">

                        <b-button class="text-danger" variant="link" size="xs"
                                  @click="deleteInvoice(row.item.id)">
                            {{ $t('delete') | toUpperCase }}
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    //Service
    import ProformaInvoice from "@/services/ProformaInvoiceService";
    //Component
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
    import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
    import qs from "qs";
    import CustomerPriceService from "@/services/CustomerPriceService";

    export default {
        components: {
        },
        props:{
            customer_id:{
                default: null
            },
            student_program_id:{
                default: null
            },
            registration_id:{
                default: null
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'created_at',
                        label: this.toUpperCase('created_at')
                    },
                    {key: 'dropdownTable', label: '#'}
                ],
                data:null
            }
        },
        methods: {
            loadData(){
                const config = {
                    params: {
                        filter: {customer_id: this.customer_id},
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                ProformaInvoice.getAll(config).then(response=>{
                    this.data = response.data.data;
                }).catch(e=>{
                    this.showErrors(e);
                });
            },
            download(row){
                ProformaInvoice.download(row.id).then(response=>{
                    this._downloadFile(response, row.data.student_number+'-'+row.created_at+'.pdf')
                })
            },
            deleteInvoice(id){
                if (id > 0) {
                    this.$swal.fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            ProformaInvoice.del(id)
                                .then(response => {
                                    this.toast(response.data);
                                    this.loadData();
                                })
                                .catch(e => {
                                    this.showErrors(e);
                                })
                        }
                    })

                }
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
