import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/customer/prices', config);
}

const get = async (id) => {
    return FinanceApi.get('/customer/prices/' + id);
}

const del = async (id) => {
    return FinanceApi.delete('/customer/prices/' + id);
}

const store = async (data) => {
    return FinanceApi.post('/customer/prices/', data);
}

const update = async (id, data) => {
    return FinanceApi.put('/customer/prices/' + id, data);
}
export default {
    get, getAll, store, update, del
}
